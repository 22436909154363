import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import map from 'lodash/map'
import max from 'lodash/max'
import uniqBy from 'lodash/uniqBy'
import { uniq } from 'lodash'

// Base Page
import BasePage from '@/pages/Base'

// Services
import OrdersService from '@/services/Orders'
import getpackage from '@/services/Shipments/ShipmentList'
import Create_Shipment from '@/services/Shipments/ShipmentList'
import PortService from '@/services/Port'


@Component({})
export default class ShipmentListCreate extends BasePage {
  constructor() {
    super()
  }

  // Search Order
  searchInputOrder: string = ''

  // User
  selectedOrders: any[] = []

  // Products Table
  tableHeaders: object[] = [
    {
      text: 'Customer Name',
      align: 'center',
      sortable: false
    },
    {
      text: 'Order ID',
      align: 'center',
      sortable: false
    },
    {
      text: 'Shipping Method',
      align: 'center',
      sortable: false
    }
  ]

  //-Table Expand-//
  tableExpand: any = {
    loading: false,
    selected: [],
    headers: [
      {
        text: '',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Packing List ID',
        align: 'left',
        sortable: true,
        class: 'table-cell-width', 
        value: 'id'
      },
      {
        text: 'Tracking Code',
        align: 'center',
        sortable: true,
        class: 'table-cell-width',
        value: 'tracking_code'
      },
      {
        text: 'Tracking Status',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Qty',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'CBM',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Weight Kgs',
        align: 'center',
        sortable: false,
        class: 'table-cell-width'
      }
    ]
  }

  // Freight Method
  freightMethod: any = 'air'

  // Shipments Details
  createShipments: any = {
    departureDate: {
      open: false,
      date: null,
      dateFormatted: null
    },
    arrivalDate: {
      open: false,
      date: null,
      dateFormatted: null
    }
  }

  tableItems: any[] = []
  tableTotalItems: number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 5,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  // Shipment Response
  airplaneWeight: string | number = '0'
  airplaneWeightUnit: string = ''
  lclCbm: string | number = '0'
  fcl20: string | number = '0'
  fcl40: string | number = '0'
  fcl40HQ: string | number = '0'
  blNumber: string = ''

  portOfLoading_item: any = []
  portOfLoading: string = ''

  portOfDestination_item: any = []
  portOfDestination: string = ''

  @Watch('tablePagination')
  @Watch('searchInputOrder')
  @Watch('freightMethod')
  onChanged() {
    this.getOrders()
  }

  mounted(){
    this.getAllPort()
  }

  onDepartureDateCancel() {
    this.createShipments.departureDate.open = false
  }
  onDepartureDateDone() {
    this.createShipments.departureDate.dateFormatted = this.toFullDate(this.createShipments.departureDate.date)
    this.createShipments.departureDate.open = false
  }

  onArrivalDateCancel() {
    this.createShipments.arrivalDate.open = false
  }
  onArrivalDateDone() {
    this.createShipments.arrivalDate.dateFormatted = this.toFullDate(this.createShipments.arrivalDate.date)
    this.createShipments.arrivalDate.open = false
  }


  async getOrders() {
    this.tableLoading = true
    var filter = 'filter[order_code][like]'
    var filter_shipment = 'filter[shipment_method][is]'
    const opts_order: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        sort: '-created_at',
        include: 'package',
        for: 'shipment',
        'filter[shipment_method][is]': '',
        'filter[order_code][like]': ''
      }
    }
    if (!isEmpty(this.searchInputOrder)) opts_order.params[filter] = this.searchInputOrder
    else delete opts_order.params[filter]
    if (!isEmpty(this.freightMethod)) opts_order.params[filter_shipment] = this.freightMethod

    var opts_package: any = {
      params: {
        for: 'shipment',
        include: 'status,packaging,order'
      }
    }

    try {
      const response = await OrdersService.getOrders(opts_order)
      const responseData = response.data
      const responseMeta = response.meta

      this.tableItems = []
      opts_package.params['filter[order_id][is]'] = null
      for (const dataOrder of responseData) {
        opts_package.params['filter[order_id][is]'] = dataOrder.id
        const responsePackage = await getpackage.getPackageNotShipment(opts_package)
        const responsePackaging = responsePackage.data.included.packaging
        const order = {
          ...dataOrder.attributes,
          package: []
        }
        if (includes(map(this.selectedOrders, 'id'), dataOrder.id)) order.isSelected = true
        else order.isSelected = false

        for (const data_package of responsePackage.data.data) {
          const data = {
            ...data_package.attributes,
            order_id : Number(dataOrder.id),
            dimensions : {
              qty : 0,
              cbm : 0,
              weight_kgs : 0
            }
          }
          for (const packaging of data_package.relationships.packaging){
            const arrayPackaging = responsePackaging[Number(packaging.id)].attributes
            var dimens = 0
            if(arrayPackaging.dimension_unit === 'meters'){
              dimens = arrayPackaging.dimensions[0].value * arrayPackaging.dimensions[1].value * arrayPackaging.dimensions[2].value
            }else if(arrayPackaging.dimension_unit === 'inchs'){
              dimens = (arrayPackaging.dimensions[0].value/39.37)*(arrayPackaging.dimensions[1].value/39.37)*(arrayPackaging.dimensions[2].value/39.37)
            }else if(arrayPackaging.dimension_unit === 'cms'){
              dimens = (arrayPackaging.dimensions[0].value/100)*(arrayPackaging.dimensions[1].value/100)*(arrayPackaging.dimensions[2].value/100)
            }
            data.dimensions.qty += arrayPackaging.item_qty
            data.dimensions.cbm += Number(dimens * arrayPackaging.package_qty)
            data.dimensions.weight_kgs += arrayPackaging.gross_weight * arrayPackaging.package_qty
          }
        
          order.package.push(data)
        }

        this.tableItems.push(order)
      }
      this.tableItems = uniqBy(this.tableItems, 'id')

      this.tableTotalItems = responseMeta.pagination.total
      this.tablePagination.totalItems = responseMeta.total

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getAllPort() {
    const payload = {
      params: {
        include: 'country'
      }
    }

    const response = await PortService.getPort(payload)

    forEach(response.data.data, data => {
      this.portOfLoading_item.push(data.attributes.name)
      this.portOfDestination_item.push(data.attributes.name)
    })
  }

  async createShipment() {
    this.showLoading({ text: 'Loading...' })
    try {
      const validation = await this.$validator.validateAll('Shipmentdetails')
      if (validation && this.tableExpand.selected.length > 0) {
        const payload_createShipment = {
          shipment_method: this.freightMethod,
          from: this.portOfLoading,
          to: this.portOfDestination,
          bl_number: this.blNumber,
          departure_date: this.createShipments.departureDate.date,
          arrival_date: this.createShipments.arrivalDate.date,
          packages: [],
          orders: []
        }
        for (const select_package of this.tableExpand.selected) {
          payload_createShipment.packages.push(select_package.id)
          payload_createShipment.orders.push(select_package.order_id)
        }
        payload_createShipment.orders = uniq(payload_createShipment.orders)

        const response =  await Create_Shipment.createShipment(payload_createShipment)
        if (response.success = true) {
          this.closeLoading()
          this.$router.push('/shipment-acn')
          this.showSnackbar({
            text: 'Create Shipment Successfully...',
            color: 'green',
            timeout: 2500
          })
        }

      } else if (this.tableExpand.selected.length <= 0) {
        this.showSnackbar({
          text: 'Please selected package of orders...!!!',
          color: 'red',
          timeout: 3000
        })
      } else {
        this.showSnackbar({
          text: 'Please fill in all fields shipment details...!!!',
          color: 'red',
          timeout: 3000
        })
      }

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
