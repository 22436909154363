import BaseService from '@/services/BaseService'

class PortService extends BaseService {
  constructor() {
    super()
  }

  async getPort(opts = {}) {
    this.endPoint = '/api/v2/ports'
    return this.get(opts)
  }

  async getOnePort(Id, opts = {}) {
    this.endPoint = 'api/v2/ports'
    return this.getOne(Id, opts)
  }

  async setPort(data = {}) {
    this.endPoint = 'api/v2/ports'
    return this.post(data)
  }

  async deletePort(id) {
    this.endPoint = 'api/v2/ports'
    return this.delete(id)
  }

  async updatePort(id, data = {}) {
    this.endPoint = 'api/v2/ports'
    return this.putOne(id, data)
  }
}
export default new PortService()
